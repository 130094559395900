<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div>
      <div class="alert alert-warning" role="alert" v-if="proponiDati">
        <b
          >Attenzione: Sono presentati i dati relativi agli impianti dell'anno
          {{ stagioneSelected - 1 }}
        </b>
        <br />
        <span v-if="!readOnly && puo_utilizzare_pulsanti_sezioni">
          Cliccando su "conferma impianti" i campi verranno riassegnati per la
          stagione selezionata</span
        >
      </div>
      <div class="row mt-5 justify-content-between" v-else>
        <div class="col-4" v-if="!readOnly && puo_utilizzare_pulsanti_sezioni">
          <div>
            <router-link
              :to="`/societa/dettaglio-societa/impianti-societa/assegna-impianto/${societa.id}`"
            >
              <button
                type="button"
                class="btn btn-light text-gray-700 fs-6 text-start"
                @click="setFetchRowsImpAss(25)"
              >
                <i class="bi bi-plus fs-6 text-gray-700"></i>assegna impianto
              </button></router-link
            >
          </div>
        </div>
        <div class="col-md-4" :class="!readOnly ? 'text-center' : ''">
          <div v-if="puo_utilizzare_pulsanti_sezioni">
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-6 text-center"
              @click="esportaLista()"
            >
              <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta
              lista filtrata
            </button>
          </div>
        </div>
        <div class="col-4">
          <div class="text-end mt-4" style="margin-right: 19px">
            Impianti assegnati:
            <b>{{ listaImpianti.length ? listaImpianti.length : "0" }}</b>
          </div>
        </div>
        <!-- <div>Lista impianti: {{ listaImpianti }}</div> -->
      </div>
      <br />
      <div v-if="loadedImpianti && listaImpianti.length">
        <div class="table-responsive card shadow">
          <!-- {{ tableElement.offsetHeight }} -->
          <table
            class="table table-striped gy-3 gs-7 align-middle"
            ref="tableElement"
          >
            <thead>
              <tr
                class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
              >
                <th scope="col">Denominazione</th>
                <th scope="col">Indirizzo</th>
                <th scope="col">Comune</th>
                <th scope="col">Campi assocati</th>
                <th
                  scope="col"
                  class="w-5"
                  v-if="!proponiDati && puo_utilizzare_pulsanti_sezioni"
                ></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in listaImpianti" :key="i">
                <tr :class="item.principale ? 'text-success' : ''">
                  <td :class="item.principale ? 'text-success' : ''">
                    <i
                      v-if="item.principale"
                      class="bi bi-pin-angle text-success fs-4"
                    >
                    </i>
                    {{ item.denominazione }}
                  </td>
                  <td :class="item.principale ? 'text-success' : ''">
                    {{ item.indirizzo }} {{ item.num_civico }}
                  </td>
                  <td :class="item.principale ? 'text-success' : ''">
                    {{ item.comune }} ({{ item.siglaProvincia }})
                  </td>
                  <td :class="item.principale ? 'text-success' : ''">
                    <template v-for="(campoItem, j) in item.campi" :key="j">
                      {{ campoItem.nome_campo }}
                      <span v-if="campoItem.nome_campo"> - </span>
                      {{ campoItem.disciplina }}
                      <br />
                    </template>
                  </td>
                  <td
                    class="w-5"
                    v-if="!proponiDati && puo_utilizzare_pulsanti_sezioni"
                  >
                    <div class="btn-group">
                      <button
                        class="btn btn-sm dropdown"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          :class="
                            item.principale
                              ? 'bi bi-three-dots-vertical text-success fs-4'
                              : 'bi bi-three-dots-vertical text-dark fs-4'
                          "
                        ></i>
                      </button>
                      <ul class="dropdown-menu" style="min-width: 18rem">
                        <a
                          href="#"
                          v-if="!readOnly && !item.principale"
                          @click="
                            setImpPrincipale(
                              item.id_impianto,
                              stagioneSelected,
                              id_societa
                            )
                          "
                        >
                          <li role="presentation" class="navi-item">
                            <div class="b-dropdown-text text-start py-2 px-4">
                              <div class="navi-link">
                                <i class="bi bi-pin-angle text-dark fs-5"></i>
                                <span class="text-dark ps-1 fs-7">
                                  Rendi principale
                                </span>
                              </div>
                            </div>
                          </li></a
                        >
                        <a
                          href="#"
                          v-if="!readOnly"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_assegna_campo"
                          @click="
                            AssCampi(
                              item.id_impianto,
                              stagioneSelected,
                              id_societa
                            )
                          "
                        >
                          <li role="presentation" class="navi-item">
                            <div class="b-dropdown-text text-start py-2 px-4">
                              <div class="navi-link">
                                <i class="bi bi-pen text-dark fs-5"></i>
                                <span class="text-dark ps-1 fs-7">
                                  Modifica assegnazione campi</span
                                >
                              </div>
                            </div>
                          </li></a
                        >
                        <router-link
                          :to="{
                            name: 'dettaglio-impianto-societa',
                            params: {
                              id: id_societa,
                              id_impianto: item.id_impianto,
                            },
                          }"
                        >
                          <li role="presentation" class="navi-item">
                            <div class="b-dropdown-text text-start py-2 px-4">
                              <div class="navi-link">
                                <i class="bi bi-eye text-dark fs-5"></i>
                                <span class="text-dark ps-1 fs-7">
                                  Visualizza
                                </span>
                              </div>
                            </div>
                          </li>
                        </router-link>
                        <router-link
                          :to="{
                            name: 'dettaglio-impianto-societa',
                            params: {
                              id: id_societa,
                              id_impianto: item.id_impianto,
                            },
                          }"
                          v-if="!readOnly"
                        >
                          <li role="presentation" class="navi-item">
                            <div class="b-dropdown-text text-start py-2 px-4">
                              <div class="navi-link">
                                <i class="bi bi-pen text-dark fs-5"></i>
                                <span class="text-dark ps-1 fs-7">
                                  Modifica
                                </span>
                              </div>
                            </div>
                          </li>
                        </router-link>
                        <router-link
                          :to="{
                            name: 'lista-omologazioni-impianto-societa',
                            params: {
                              id: id_societa,
                              id_impianto: item.id_impianto,
                              denominazione_impianto: item.denominazione,
                            },
                          }"
                          v-if="!readOnly"
                        >
                          <li role="presentation" class="navi-item">
                            <div class="b-dropdown-text text-start py-2 px-4">
                              <div class="navi-link">
                                <i
                                  class="bi bi-bookmark-check text-dark fs-5"
                                ></i>
                                <span class="text-dark ps-1 fs-7">
                                  Omologazioni
                                </span>
                              </div>
                            </div>
                          </li>
                        </router-link>
                        <!-- NON E PREVISTA LA POSSIBILITA DI RIMUOVERE L'IMPIANTO! -->
                        <!-- <li role="presentation" class="navi-item">
                      <div class="b-dropdown-text text-start py-2 px-4">
                        <a href="#" class="navi-link">
                          <i class="bi bi-trash text-dark fs-5"></i>
                          <span class="text-dark ps-1 fs-7">
                            Rimuovi impianto
                          </span>
                        </a>
                      </div>
                    </li> -->
                      </ul>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="pt-5 text-end" v-if="!readOnly && proponiDati">
          <router-link
            :to="`/societa/dettaglio-societa/impianti-societa/assegna-impianto/${societa.id}`"
          >
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-6 text-start"
              @click="setFetchRowsImpAss(25)"
            >
              <i class="bi bi-plus fs-6 text-gray-700"></i>assegna manualmente
            </button></router-link
          >
          <button
            type="button"
            class="badge bg-secondary rounded blueFit fs-5 ms-5"
            @click="riassegnaImpianti()"
          >
            Conferma impianti
          </button>
        </div>
      </div>
      <div
        v-else-if="loadedImpianti && !listaImpianti.length"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessun impianto.
        </span>
      </div>
      <div v-else>
        <div class="mx-auto my-auto text-center">
          <div class="spinner-border text-gray-600" role="status"></div>
          <span class="text-gray-600 ps-3 fs-2">Loading</span>
        </div>
      </div>
      <br />
    </div>
  </div>
  <AssegnazioneCampi
    :impianto="selectedImpianto"
    :societa="societa"
    :isLoadingDetail="isLoadingDetail"
    @loadImpiantiSocieta="refreshList"
  />
</template>

<script>
const debugPrint = true;
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import {
  viewEditImpianto,
  setPrincipale,
  confermaAssegnazione,
} from "@/requests/addImpianto";
import AssegnazioneCampi from "@/components/components-fit/società/impianti/AssegnazioneCampi.vue";
import services from "../../../../axios/dbManag";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "impianti-societa",
  components: { AssegnazioneCampi, Loading },
  props: ["societa", "campiLocked"],
  setup(props) {
    const loadedImpianti = ref(false);
    const listaImpianti = ref([]);
    const store = useStore();
    const isLoading = ref(false);

    const stagioneSelected = computed(() => store.getters.stagioneSelected);
    const id_societa = ref(props.societa.id);
    const proponiDati = ref(false);

    const loadImpiantiSocieta = () => {
      loadedImpianti.value = false;
      proponiDati.value = false;
      services.dbManag
        .post(`/affiliazioni/societa/impianti/list`, {
          anno: stagioneSelected.value,
          id_societa: props.societa.id,
        })
        .then((res) => {
          listaImpianti.value.splice(0, listaImpianti.value.length);
          res.data.results.forEach((element) => {
            listaImpianti.value.push(element);
          });
          loadedImpianti.value = true;
          if (listaImpianti.value.length > 0) {
            proponiDati.value = res.data.proponiDati;
          }
        });
    };
    loadImpiantiSocieta();

    const refreshList = () => {
      loadImpiantiSocieta();
    };

    const isLoadingDetail = ref(false);

    const selectedImpianto = ref({});
    const AssCampi = (id_Impianto, anno, id_Societa) => {
      selectedImpianto.value = {};
      isLoadingDetail.value = true;
      viewEditImpianto(id_Impianto, anno, id_Societa).then((res) => {
        if (res.length > 0) {
          selectedImpianto.value = res[0];
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "kt_modal_assegna_campo"
          );
        }
        isLoadingDetail.value = false;
      });
    };

    const setImpPrincipale = async (id_Impianto, anno, id_Societa) => {
      isLoading.value = true;
      selectedImpianto.value = {};
      await setPrincipale(id_Impianto, anno, id_Societa).then((res) => {
        isLoading.value = false;
        selectedImpianto.value = res[0];
        if (res.statusCode === 200) {
          refreshList();
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    };

    watch(stagioneSelected, () => {
      loadImpiantiSocieta();
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Impianti Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    const riassegnaImpianti = () => {
      Swal.fire({
        title: "Sei Sicuro?",
        html: "Procedendo gli impianti verranno assegnati alla società",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          confermaAssegnazione(props.societa.id, stagioneSelected.value).then(
            (res) => {
              if (res.data.statusCode == 200) {
                alertSuccess("Campi assegnati correttamente");
                loadImpiantiSocieta();
              } else {
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
            }
          );
        }
      });
    };

    const tableElement = ref(null);

    const setFetchRowsImpAss = (e) => {
      store.commit("setFetchRowsImpAss", e);
    };

    return {
      tableElement,

      esportaLista,
      listaImpianti,
      loadedImpianti,
      AssCampi,
      selectedImpianto,
      setImpPrincipale,
      id_societa,
      stagioneSelected,
      refreshList,
      isLoading,
      isLoadingDetail,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      proponiDati,
      riassegnaImpianti,
      setFetchRowsImpAss,

      puo_utilizzare_pulsanti_sezioni: computed(
        () => store.getters.puo_utilizzare_pulsanti_sezioni
      ),
    };
  },
});
</script>
